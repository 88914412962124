<template>
    <div class="mt-0 mb-32">
        <div id="header">
            <h2><i class="el-icon-wind-power mr-16"></i>Weekly Ranking Procedures</h2>

            <ErrorDisplay :requestError="requestError" @closed="requestError = null" />

            <CsvExporter :visibleSync="csvExporterVisible" @close="csvExporterVisible = false" />
        </div>

        <FormGroup v-loading="loadingWtaTourYears || loadingWtaSystem" :layout="layoutSystem" :model="wtaSystem" :schema="schemaSystem" @change="onChange" />

        <el-card class="box-card mb-32 mt-32" shadow="hover">
            <div slot="header" class="clearfix">
                <span><b>Ranking Procedure</b></span>
            </div>
            <div class="flex-row mt-0 mb-0">
                <el-alert :closable="false" :show-icon="true" class="mr-32" type="info">
                    <div>Creates new ranking for selected ranking date.</div>
                    <div>Updates database table player_rank_current, player_rank_history, team_points, team_points_history, player_master, wta_system.</div>
                    <div>After each successful Ranking Run, doubles entry ranks are updated.</div>
                    <div>In addition, after Ranking has finished, Eligibility Dates for Player Development are updated.</div>
                    <div>Select a new current Ranking Date. Select a new next Ranking Date. Monday will be chosen by default.</div>
                </el-alert>                              
                <div class="flex-row">
                    <div>
                        <el-tooltip :disabled="true" :tabindex="-1" placement="top-start">
                            <label> New Current Ranking Date </label>
                        </el-tooltip>
                        <el-date-picker
                            v-model="rankingDate"                                                       
                            format="yyyy-MM-dd"
                            placeholder="Select Current Ranking Date"
                            type="date"
                            @change="handleCurrentRankinkDateChange"
                        ></el-date-picker>
                    </div>
                    <div>
                        <el-tooltip :disabled="true" :tabindex="-1" placement="top-start">
                            <label>New Next Ranking Date</label>
                        </el-tooltip>
                        <el-date-picker
                            v-model="nextRankingDate"                           
                            format="yyyy-MM-dd"
                            placeholder="Select Next Ranking Date"
                            type="date"
                            @change="handleNextRankingDateChange"
                        ></el-date-picker>
                    </div>
                </div>
            </div>
            <div class="flex-row mt-0 mb-32">
                <span class="spacer"></span>
                <el-button
                    v-loading="runningRankingProcedure || updatingDoublesEntryRanks || updatingEligibilityDates"
                    plain
                    size="big"
                    type="primary"
                    @click="onClickRankingProcedure"
                    :disabled="isBaselineReader"
                >
                    Run Ranking Procedure
                </el-button>
            </div>
            <div class="mt-16" v-if="runningRankingProcedure || updatingDoublesEntryRanks || updatingEligibilityDates">
                <el-progress :percentage="rankingProcedureProgress" max="100"></el-progress>
            </div>
            <div class="mt-16" v-if="rankingProcedureProgress === 100 && !runningRankingProcedure && !updatingDoublesEntryRanks && !updatingEligibilityDates">
                <el-progress :percentage="rankingProcedureProgress" status="success" max="100"></el-progress>
            </div>
        </el-card>

        <el-card class="box-card mb-32 mt-32" shadow="hover">
            <div slot="header" class="clearfix">
                <span><b>Select tournament year for update</b></span>
            </div>
            <div class="flex-row mt-0 mb-32">
                <el-alert :closable="false" :show-icon="true" class="mr-32" type="info">
                    <div>By default calendar year of current ranking date is selected.</div>
                    <div>Select past years when you want to repair data for past years.</div>
                    <div>Affects Round Result Split and Update YTD Totals.</div>
                </el-alert>
                <div class="flex-row mr-16">
                    <span class="mr-16" style="white-space: nowrap">tourn year: </span>
                    <el-select v-model="year" v-loading="loadingWtaTourYears || loadingWtaSystem" class="year-select" placeholder="Select TourYear">
                        <el-option v-for="(item, i) in wtaTourYears" :key="i" :label="item.tourYear" :value="item.tourYear"></el-option>
                    </el-select>
                </div>
            </div>
        </el-card>

        <el-card class="box-card mb-32 mt-32" shadow="hover">
            <div slot="header" class="clearfix">
                <span><b>Round Result Split</b></span>
            </div>
            <div class="flex-row mt-0 mb-32">
                <el-alert :closable="false" :show-icon="true" class="mr-32" type="info">
                    <div>Updates database table tourn_rounds_q for selected tourn_year with data from table tourn_rounds_m.</div>
                    <div>Tables hold data for Round Results and are used in WTA Tour CSV Files export.</div>
                    <div>Run this procedure before running Update YTD Totals.</div>
                </el-alert>
                <span class="spacer"></span>
                <div>
                    <el-button v-loading="runningTournRoundSplit" plain size="big" type="primary" @click="onClickTournRoundSplit" :disabled="isBaselineReader">
                        Run Round Result Split
                    </el-button>
                </div>
            </div>
            <div class="mt-16" v-if="runningTournRoundSplit && tournResultProgress !== 100">
                <el-progress :percentage="tournResultProgress" max="100"></el-progress>
            </div>
            <div class="mt-16" v-if="!runningTournRoundSplit && tournResultProgress === 100">
                <el-progress :percentage="tournResultProgress" status="success" max="100"></el-progress>
            </div>
        </el-card>

        <el-card class="box-card mb-32 mt-32" shadow="hover">
            <div slot="header" class="clearfix">
                <span><b>Update YTD Totals</b></span>
            </div>
            <div class="flex-row mt-0 mb-32">
                <el-alert :closable="false" :show-icon="true" class="mr-32" type="info">
                    <div>Updates database table player_stats and player_master for selected tourn_year with data from various tables.</div>
                    <div>Run this procedure before exporting WTA Tour CSV Files.</div>
                </el-alert>
                <span class="spacer"></span>
                <div>
                    <el-button v-loading="runningUpdateYtdTotals" plain size="big" type="primary" @click="onClickUpdateYtdTotals" :disabled="isBaselineReader">
                        Run Update YTD Totals
                    </el-button>
                </div>
            </div>
            <div class="mt-16" v-if="runningUpdateYtdTotals && updateYtdTotalsProgress !== 100">
                <el-progress :percentage="updateYtdTotalsProgress" max="100"></el-progress>
            </div>
            <div class="mt-16" v-if="updateYtdTotalsProgress === 100 && !runningUpdateYtdTotals">
                <el-progress :percentage="updateYtdTotalsProgress" status="success" max="100"></el-progress>
            </div>
        </el-card>

        <el-card class="box-card mb-32 mt-32" shadow="hover">
            <div slot="header" class="clearfix">
                <span><b>Weekly Data Export</b></span>
            </div>
            <div class="flex-row mt-0">
                <el-alert type="info" :closable="false" :show-icon="true" class="mr-32">
                    <div>Run this procedure as part of the weekly rankings.</div>
                    <div>This will export data from Sybase and import that data into the following tables in Azure DB:</div>
                    <div>
                        Players, player_master, bios, player_bio_expand, EntrySingles, EntryDoubles, Rankings, PlayerRankCurrent, player_rank_history,
                        player_specialrank, tourn_table_full, tourn_mast, tourn_required, tourn_points, tourn_results, tourn_rounds_q, career_results,
                        ytd_results, Activity, team_champs, GrandSlamAndChamps, GS_WL_Record, TeamPointsHistory, Umpires, Messaging_Recipients.
                    </div>
                    <div>
                        <b>
                            NOTE: This is a long running process and may take 40 minutes or more to run. Keep a window open on this page and when it's complete
                            it will notify you.
                        </b>
                    </div>
                </el-alert>
                <div v-if="mode==='production' || useTestForWeeklyDataExport==true">
                    <el-button @click="onClickWeeklyDataExport"
                               type="primary"
                               size="big"
                               plain
                               v-loading="runningWeeklyDataExport"
                               :disabled="
                            runningWeeklyDataExport ||
                            runningTournamentDataExport ||
                            runningPlayerBioDataExport ||
                            runningMessagingRecipientsDataExport ||
                            runningPlayersDataExport ||
                            isBaselineReader
                        ">
                        Run Weekly Data Export
                    </el-button>
                    <div class="flex-row mt-8">
                        <el-checkbox :disabled="isBaselineReader" v-model="useTestForWeeklyDataExport">Use Test Tables</el-checkbox>
                    </div>
                </div>
                <div v-else-if="mode !='production' || useTestForWeeklyDataExport==false">
                    <el-button @click="onClickWeeklyDataExport"
                               type="primary"
                               size="big"
                               plain
                               v-loading="runningWeeklyDataExport"
                               :disabled="true">
                        Run Weekly Data Export
                    </el-button>
                    <div class="flex-row mt-8">
                        <el-checkbox :disabled="isBaselineReader" v-model="useTestForWeeklyDataExport">Use Test Tables</el-checkbox>
                    </div>
                </div>
            </div>
            <div>
                <el-collapse accordion>
                    <el-collapse-item title="Individual Exports" name="1">
                        <div class="mt-16" v-if="mode==='production' || useTestForWeeklyDataExport==true">
                            <el-button @click="onClickExportTournamentData"
                                       type="primary"
                                       size="big"
                                       plain
                                       v-loading="runningTournamentDataExport"
                                       :disabled="
                                    runningWeeklyDataExport ||
                                    runningTournamentDataExport ||
                                    runningPlayerBioDataExport ||
                                    runningMessagingRecipientsDataExport ||
                                    runningPlayersDataExport ||
                                    isBaselineReader
                                ">
                                Run Tournament Data Export
                            </el-button>
                            <el-button @click="onClickExportPlayerBioData"
                                       type="primary"
                                       size="big"
                                       plain
                                       v-loading="runningPlayerBioDataExport"
                                       :disabled="
                                    runningWeeklyDataExport ||
                                    runningTournamentDataExport ||
                                    runningPlayerBioDataExport ||
                                    runningMessagingRecipientsDataExport ||
                                    runningPlayersDataExport ||
                                    isBaselineReader
                                ">
                                Run Player Bio Data Export
                            </el-button>
                            <el-button @click="onClickExportMessagingRecipientsData"
                                       type="primary"
                                       size="big"
                                       plain
                                       v-loading="runningMessagingRecipientsDataExport"
                                       :disabled="
                                    runningWeeklyDataExport ||
                                    runningTournamentDataExport ||
                                    runningPlayerBioDataExport ||
                                    runningMessagingRecipientsDataExport ||
                                    runningPlayersDataExport ||
                                    isBaselineReader
                                ">
                                Run Messaging Recipients Data Export
                            </el-button>
                            <el-button @click="onClickExportPlayersData"
                                       type="primary"
                                       size="big"
                                       plain
                                       v-loading="runningPlayersDataExport"
                                       :disabled="
                                    runningWeeklyDataExport ||
                                    runningTournamentDataExport ||
                                    runningPlayerBioDataExport ||
                                    runningMessagingRecipientsDataExport ||
                                    runningPlayersDataExport ||
                                    isBaselineReader
                                ">
                                Run Players Data Export
                            </el-button>
                        </div>
                        <div class="mt-16" v-else-if="mode !='production' || useTestForWeeklyDataExport==false">
                            <el-button @click="onClickExportTournamentData"
                                       type="primary"
                                       size="big"
                                       plain
                                       v-loading="runningTournamentDataExport"
                                       :disabled="true">
                                Run Tournament Data Export
                            </el-button>
                            <el-button @click="onClickExportPlayerBioData"
                                       type="primary"
                                       size="big"
                                       plain
                                       v-loading="runningPlayerBioDataExport"
                                       :disabled="true">
                                Run Player Bio Data Export
                            </el-button>
                            <el-button @click="onClickExportMessagingRecipientsData"
                                       type="primary"
                                       size="big"
                                       plain
                                       v-loading="runningMessagingRecipientsDataExport"
                                       :disabled="true">
                                Run Messaging Recipients Data Export
                            </el-button>
                            <el-button @click="onClickExportPlayersData"
                                       type="primary"
                                       size="big"
                                       plain
                                       v-loading="runningPlayersDataExport"
                                       :disabled="true">
                                Run Players Data Export
                            </el-button>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>
            <div
                class="mt-16"
                v-if="
                    runningWeeklyDataExport ||
                    runningTournamentDataExport ||
                    runningPlayerBioDataExport ||
                    runningMessagingRecipientsDataExport ||
                    runningPlayersDataExport ||
                    dataExporterLoadingError !== null
                "
            >
                <el-progress :percentage="dataExportProgress" max="100"></el-progress>
            </div>
            <div
                class="mt-16"
                v-if="
                    !runningWeeklyDataExport &&
                    !runningTournamentDataExport &&
                    !runningPlayerBioDataExport &&
                    !runningMessagingRecipientsDataExport &&
                    !runningPlayersDataExport &&
                    dataExporterLoadingError === null &&
                    dataExportProgress === 100
                "
            >
                <el-progress :percentage="dataExportProgress" status="success" max="100"></el-progress>
            </div>
        </el-card>

        <!--
        <el-card class="box-card mb-32 mt-32" shadow="hover">
          <div slot="header" class="clearfix">
            <span><b>Update Doubles Entry Ranks</b></span>
          </div>
          <div class="flex-row mt-0 mb-32">
            <el-alert type="info" :closable="false" :show-icon="true" class="mr-32">
              <div>Updates database table tourn_entry_d, sets updated ranks for doubles entries..</div>
              <div>Run this procedure after each successful Ranking Run.</div>
            </el-alert>
            <span class="spacer"></span>
            <div>
              <el-button @click="onClickUpdateDoublesEntryRanks" type="primary" size="big" plain
                         v-loading="updatingDoublesEntryRanks">
                Update Doubles Entry Ranks
              </el-button>
            </div>
          </div>
        </el-card>
        -->

        <el-card class="box-card mb-32 mt-32" shadow="hover">
            <div slot="header" class="clearfix">
                <span><b>WTA Tour CSV Files</b></span>
            </div>
            <div class="flex-row mt-0 mb-32">
                <el-alert :closable="false" :show-icon="true" class="mr-32" type="info">
                    <div>Opens a new dialog which allows for WTA Tour CSV Files Export to update WTA website.</div>
                    <div>Download WTA Tour CSV Files for local editing and upload them to Azure BLOB Storage.</div>
                    <div>Downloads are stored in default Download folder. Please allow downloads when asked by Browser.</div>
                </el-alert>
                <span class="spacer"></span>
                <div>
                    <el-button plain size="big" type="primary" @click="csvExporterVisible = true"> Show WTA Tour CSV Files </el-button>
                </div>
            </div>
        </el-card>

        <el-card class="box-card mb-32 mt-32" shadow="hover">
            <div slot="header" class="clearfix">
                <span><b>5 PDFs for Website</b></span>
            </div>
            <div class="flex-row mt-0 mb-32">
                <el-alert :closable="false" :show-icon="true" class="mr-32" type="info">
                    <div>
                        Download 5 PDFs which go on WTA website: All_YTD_Prize_Money.pdf, All_Career_Prize_Money.pdf, Championship_Leaders.pdf,
                        Singles_Numeric.pdf, Doubles_Numeric.pdf.
                    </div>
                    <div>Downloads are stored in default Download folder. Please allow downloads when asked by Browser.</div>
                </el-alert>
                <span class="spacer"></span>
                <div>
                    <el-button v-loading="loading5PdfsForWebsite" plain size="big" type="primary" @click="onClick5PdfsForWebsite">
                        Download 5 PDFs for Website
                    </el-button>
                </div>
            </div>
            <div class="mt-16" v-if="loading5PdfsForWebsite">
                <el-progress :percentage="pdfDownloadProgress" max="100"></el-progress>
            </div>
            <div class="mt-16" v-if="pdfDownloadProgress === 100 && !loading5PdfsForWebsite">
                <el-progress :percentage="pdfDownloadProgress" status="success" max="100"></el-progress>
            </div>
        </el-card>
    </div>
</template>

<script>
import { WtaTourYear } from '../models/wta-tour-year';
import { WtaSystem } from '../models/wta-system';
import { mapActions, mapGetters } from 'vuex';
import * as moment from 'moment';
import { isBaselineReader } from '../services/authGroups';

export default {
    data() {
        return {
            requestError: null,
            year: null,
            csvExporterVisible: false,
            pdfReportsVisible: false,
            schemaSystem: WtaSystem.schema,
            layoutSystem: WtaSystem.layoutRankingDates,
            rankingDate: null,
            nextRankingDate: null,
            useTestForWeeklyDataExport: false,
            isBaselineReader: isBaselineReader(),
            mode: process.env.VUE_APP_APPLICATION_MODE,

        };
    },
    computed: {
        ...mapGetters('rankingProcedure', ['runningRankingProcedure', 'rankingProcedureResult', 'rankingProcedureError', 'rankingProcedureProgress']),
        ...mapGetters('playerDevelopments', ['updatingEligibilityDates']),
        ...mapGetters('tournamentRounds', ['runningTournRoundSplit', 'tournResultProgress']),
        ...mapGetters('playerStats', ['runningUpdateYtdTotals', 'playerStatsLoadingError', 'updateYtdTotalsProgress']),
        ...mapGetters('dataExporter', [
            'runningWeeklyDataExport',
            'runningTournamentDataExport',
            'runningPlayerBioDataExport',
            'runningMessagingRecipientsDataExport',
            'runningPlayersDataExport',
            'dataExportProgress',
            'dataExporterLoadingError',
        ]),
        ...mapGetters('doublesEntries', ['updatingDoublesEntryRanks', 'updatingErrorDoublesEntryRanks']),
        ...mapGetters('wtaTourYears', ['wtaTourYears', 'loadingWtaTourYears']),
        ...mapGetters('wtaSystem', ['wtaSystem', 'loadingWtaSystem']),
        ...mapGetters('pdfReports', [
            'loadingYtdPrizeMoneyLeaders',
            'loadingCareerPrizeMoneyLeaders',
            'loadingChampionshipLeaders',
            'loadingSinglesNumeric',
            'loadingDoublesNumeric',
            'loadingError',
            'pdfDownloadProgress',
        ]),
        loading5PdfsForWebsite() {
            return (
                this.loadingYtdPrizeMoneyLeaders ||
                this.loadingCareerPrizeMoneyLeaders ||
                this.loadingChampionshipLeaders ||
                this.loadingSinglesNumeric ||
                this.loadingDoublesNumeric
            );
        },
    },
    mounted() {
        this.refresh();        
    },
    methods: {
        ...mapActions('rankingProcedure', ['startRankingProcedure']),
        ...mapActions('playerDevelopments', ['updateEligibilityDates']),
        ...mapActions('tournamentRounds', ['runTournRoundSplit']),
        ...mapActions('playerStats', ['updateYtdTotals']),
        ...mapActions('playerStats', ['updateYtdTotals']),
        ...mapActions('dataExporter', [
            'weeklyDataExport',
            'tournamentDataExport',
            'playerBioDataExport',
            'messagingRecipientsDataExport',
            'playersDataExport',
        ]),
        ...mapActions('doublesEntries', ['updateDoublesEntryRanks']),
        ...mapActions('wtaTourYears', ['retrieveValidWtaTourYears']),
        ...mapActions('wtaSystem', ['retrieveWtaSystem']),
        ...mapActions('pdfReports', [
            'getYtdPrizeMoneyLeaders',
            'getCareerPrizeMoneyLeaders',
            'getChampionshipLeaders',
            'getSinglesNumeric',
            'getDoublesNumeric',
            'clearProgress',
        ]),    
        handleCurrentRankinkDateChange(date) {
            if (date) {
                // Get the selected date's Monday of the same week
                const monday = this.getMonday(date);
                this.rankingDate = monday;
            }
        },
        handleNextRankingDateChange(date) {
            if (date) {
                // Get the selected date's Monday of the same week
                const monday = this.getMonday(date);
                this.nextRankingDate = monday;
            }
        },
        getMonday(date) {
            const newDate = new Date(date);
            const day = newDate.getDay(),
            diff = newDate.getDate() - day + (day == 0 ? -6 : 1); // Adjust for Sunday
            newDate.setDate(diff);
            return newDate;
        },    
        refresh() {            
            this.requestError = null;
            this.retrieveValidWtaTourYears();
            this.retrieveWtaSystem()
                .then(() => {                    
                    this.year = moment(this.wtaSystem.data.currentRankDate).year();                    
                    this.rankingDate = this.wtaSystem.data.nextRankDate;                    
                    this.nextRankingDate = moment(this.wtaSystem.data.nextRankDate).add(7, 'd');                   
                })
                .catch((error) => {
                    this.requestError = error;
                    this.$notify({
                        title: 'Get WTA System Error',
                        message: error.response.data.Message,
                        type: 'error',
                        duration: 7000,
                    });
                });                
        },
        reset() {},
        saveChanges() {},
        onChange() {},
        onClickRankingProcedure() {
            this.requestError = null;
            this.$confirm('You are about to run the Ranking procedure. Please confirm.', 'Ranking procedure', {
                confirmButtonText: 'Run Ranking procedure',
                cancelButtonText: 'Cancel',
                type: 'warning',
            })            
                .then(() => {                    
                    let options = {
                        rankingDate: moment(this.rankingDate).format('YYYY-MM-DD'),
                        nextRankingDate: moment(this.nextRankingDate).format('YYYY-MM-DD'),
                    };
                    this.startRankingProcedure(options)
                        .then((result) => {
                            // console.log(result);
                            this.refresh();
                            this.$notify({
                                title: 'Ranking procedure',
                                message: 'Ranking procedure finished successfully',
                                type: 'success',
                                duration: 7000,
                            });
                            this.runUpdateEligibilityDates();
                        })
                        .catch((error) => {
                            this.requestError = error;
                            this.$notify({
                                title: 'Ranking procedure',
                                message: error.response.data.Message || error,
                                type: 'error',
                                duration: 9000,
                            });
                        });
                })
                .catch((e) => {});
        },
        runUpdateEligibilityDates() {
            this.updateEligibilityDates()
                .then((result) => {
                    // console.log(result);
                    this.runUpdateDoublesEntryRanks();
                    this.$notify({
                        title: 'Player Development',
                        message: result + ' Eligibility Dates updated.',
                        type: 'success',
                        duration: 7000,
                    });
                })
                .catch((error) => {
                    this.requestError = error;
                    this.$notify({
                        title: 'Player Development',
                        message: 'Update Eligibility Dates failed!',
                        type: 'error',
                        duration: 9000,
                    });
                });
        },
        runUpdateDoublesEntryRanks() {
            this.updateDoublesEntryRanks()
                .then((result) => {
                    this.$notify({
                        title: 'Update Doubles Entry Ranks',
                        message: result.data + ' Entries updated successfully.',
                        type: 'success',
                        duration: 7000,
                    });
                })
                .catch((error) => {
                    this.requestError = error;
                    this.$notify({
                        title: 'Update Doubles Entry Ranks',
                        message: error.response.data.Message,
                        type: 'error',
                        duration: 9000,
                    });
                });
        },
        onClickTournRoundSplit() {
            this.requestError = null;
            this.$confirm('You are about to run "Tourn Round Split" procedure. Please confirm.', {
                confirmButtonText: 'Run Round Result Split',
                cancelButtonText: 'Cancel',
                type: 'warning',
            })
                .then(() => {
                    let data = {
                        season: this.year,
                    };
                    this.runTournRoundSplit(data)
                        .then((result) => {
                            this.$notify({
                                title: 'Round Result Split',
                                message: 'Round Result Split finished successfully.',
                                type: 'success',
                                duration: 7000,
                            });
                        })
                        .catch((error) => {
                            this.requestError = error;
                            this.$notify({
                                title: 'Round Result Split',
                                message: error.response.data.Message,
                                type: 'error',
                                duration: 9000,
                            });
                        });
                })
                .catch(() => {});
        },
        onClickUpdateDoublesEntryRanks() {
            this.requestError = null;
            this.$confirm('You are about to run "Update Doubles Entry Ranks" procedure. Please confirm.', {
                confirmButtonText: 'Update Doubles Entry Ranks',
                cancelButtonText: 'Cancel',
                type: 'warning',
            })
                .then(() => {
                    this.updateDoublesEntryRanks()
                        .then((result) => {
                            this.$notify({
                                title: 'Update Doubles Entry Ranks',
                                message: result.data + ' Entries updated successfully.',
                                type: 'success',
                                duration: 7000,
                            });
                        })
                        .catch((error) => {
                            this.requestError = error;
                            this.$notify({
                                title: 'Update Doubles Entry Ranks',
                                message: error.response.data.Message,
                                type: 'error',
                                duration: 9000,
                            });
                        });
                })
                .catch(() => {});
        },
        onClickUpdateYtdTotals() {
            this.requestError = null;
            this.$confirm('You are about to run "Update YTD Totals" procedure. Please confirm.', {
                confirmButtonText: 'Run Update YTD Totals',
                cancelButtonText: 'Cancel',
                type: 'warning',
            })
                .then(() => {
                    var wtaTourYear = new WtaTourYear({
                        tourYear: this.year,
                        startDate: moment(new Date(this.year, 0, 1)).format('YYYY-MM-DD'),
                        endDate: moment(new Date(this.year, 11, 31)).format('YYYY-MM-DD'),
                        isValid: true,
                    });
                    this.updateYtdTotals(wtaTourYear.data)
                        .then((result) => {
                            console.log(result);
                            this.$notify({
                                title: 'Update YTD Totals',
                                message: 'Update YTD Totals finished successfully',
                                type: 'success',
                                duration: 7000,
                            });
                        })
                        .catch((error) => {
                            this.requestError = error;
                            this.$notify({
                                title: 'Update YTD Totals',
                                message: error.response.data.Message,
                                type: 'error',
                                duration: 9000,
                            });
                        });
                })
                .catch(() => {});
        },
        onClickWeeklyDataExport() {
            this.requestError = null;
            this.$confirm(
                `You are about to run "Weekly Data Export" procedure on ${this.useTestForWeeklyDataExport ? 'TEST' : 'PRODUCTION'}. Please confirm.`,
                {
                    confirmButtonText: 'Run Weekly Data Export',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                },
            )
                .then(() => {
                    this.weeklyDataExport(this.useTestForWeeklyDataExport)
                        .then((data) => {
                            this.$notify({
                                title: 'Weekly Data Export',
                                message: 'Weekly Data Export finished successfully',
                                type: 'success',
                                duration: 0,
                            });
                        })
                        .catch((error) => {
                            this.requestError = error;
                            this.$notify({
                                title: 'Weekly Data Export',
                                message: error.response.data.Message,
                                type: 'error',
                                duration: 0,
                            });
                        });
                })
                .catch(() => {});
        },
        onClickExportTournamentData() {
            this.requestError = null;
            this.$confirm(
                `You are about to run "Tournament Data Export" procedure on ${this.useTestForWeeklyDataExport ? 'TEST' : 'PRODUCTION'}. This will update the website with data from each tournament (In Baseline, data from each tournaments General tab). Please confirm.`,
                {
                    confirmButtonText: 'Run Tournament Data Export',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                },
            )
                .then(() => {
                    this.tournamentDataExport(this.useTestForWeeklyDataExport)
                        .then((data) => {
                            this.$notify({
                                title: 'Tournament Data Export',
                                message: 'Tournament Data Export finished successfully',
                                type: 'success',
                                duration: 0,
                            });
                        })
                        .catch((error) => {
                            this.requestError = error;
                            this.$notify({
                                title: 'Tournament Data Export',
                                message: error.response.data.Message,
                                type: 'error',
                                duration: 0,
                            });
                        });
                })
                .catch(() => {});
        },
        onClickExportPlayerBioData() {
            this.requestError = null;
            this.$confirm(
                `You are about to run "Player Bio Data Export" procedure on ${this.useTestForWeeklyDataExport ? 'TEST' : 'PRODUCTION'}. This will update the website with data from each player (In Baseline, data from each players Bio tab). Please confirm.`,
                {
                    confirmButtonText: 'Run Player Bio Data Export',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                },
            )
                .then(() => {
                    this.playerBioDataExport(this.useTestForWeeklyDataExport)
                        .then((data) => {
                            this.$notify({
                                title: 'Player Bio Data Export',
                                message: 'Player Bio Data Export finished successfully',
                                type: 'success',
                                duration: 0,
                            });
                        })
                        .catch((error) => {
                            this.requestError = error;
                            this.$notify({
                                title: 'Player Bio Data Export',
                                message: error.response.data.Message,
                                type: 'error',
                                duration: 0,
                            });
                        });
                })
                .catch(() => {});
        },
        onClickExportMessagingRecipientsData() {
            this.requestError = null;
            this.$confirm(
                `You are about to run "Messaging Recipients Data Export" procedure on ${this.useTestForWeeklyDataExport ? 'TEST' : 'PRODUCTION'}. This will update the list of messaging recipients. Please confirm.`,
                {
                    confirmButtonText: 'Run Messaging Recipients Data Export',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                },
            )
                .then(() => {
                    this.messagingRecipientsDataExport(this.useTestForWeeklyDataExport)
                        .then((data) => {
                            this.$notify({
                                title: 'Messaging Recipients Data Export',
                                message: 'Messaging Recipients Data Export finished successfully',
                                type: 'success',
                                duration: 0,
                            });
                        })
                        .catch((error) => {
                            this.requestError = error;
                            this.$notify({
                                title: 'Messaging Recipients Data Export',
                                message: error.response.data.Message,
                                type: 'error',
                                duration: 0,
                            });
                        });
                })
                .catch(() => {});
        },
        onClickExportPlayersData() {
            this.requestError = null;
            this.$confirm(
                `You are about to run "Players Data Export" procedure on ${this.useTestForWeeklyDataExport ? 'TEST' : 'PRODUCTION'}. This will update the website with data from players general information including highest rank (In Baseline, data from each players General tab). Please confirm.`,
                {
                    confirmButtonText: 'Run Players Data Export',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                },
            )
                .then(() => {
                    this.playersDataExport(this.useTestForWeeklyDataExport)
                        .then((data) => {
                            this.$notify({
                                title: 'Players Data Export',
                                message: 'Players Data Export finished successfully',
                                type: 'success',
                                duration: 0,
                            });
                        })
                        .catch((error) => {
                            this.requestError = error;
                            this.$notify({
                                title: 'Players Data Export',
                                message: error.response.data.Message,
                                type: 'error',
                                duration: 0,
                            });
                        });
                })
                .catch(() => {});
        },

        onClick5PdfsForWebsite() {
            this.clearProgress();
            this.downloadPdf(this.getYtdPrizeMoneyLeaders, 'All_YTD_Prize_Money.pdf');
            this.downloadPdf(this.getCareerPrizeMoneyLeaders, 'All_Career_Prize_Money.pdf');
            this.downloadPdf(this.getChampionshipLeaders, 'Championship_Leaders.pdf');
            this.downloadPdf(this.getSinglesNumeric, 'Singles_Numeric.pdf');
            this.downloadPdf(this.getDoublesNumeric, 'Doubles_Numeric.pdf');
        },

        downloadPdf(fDownload, filename) {
            let options = {};
            this.requestError = null;
            fDownload(options)
                .then((result) => {
                    const file = new Blob([result], { type: 'application/pdf' });
                    saveAs(file, filename);
                    this.$notify({
                        title: '5 PDFs for Website: PDF download successful',
                        message: filename + ' downloaded',
                        type: 'success',
                        duration: 5000,
                    });
                })
                .catch((error) => {
                    this.requestError = error;
                    this.$notify({
                        title: '5 PDFs for Website: PDF download failed',
                        message: filename + ': ',
                        type: 'error',
                        duration: 7000,
                    });
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.text {
    font-size: 14px;
}

.item {
    margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: '';
}

.clearfix:after {
    clear: both;
}

label {
    font-size: 12px;
    color: #444;
    display: block;
    padding-left: 8px;
}
</style>
