import { Model, isValid } from './model';
import { icoCountries } from '../utils/country-codes';

export class SpecialRankingOverview extends Model {
    isValidForAdd() {
        return isValid(SpecialRankingOverview.schemaAdd, this);
    }

    isValidForEdit() {
        return isValid(SpecialRankingOverview.schema, this);
    }
}

SpecialRankingOverview.layout = {
    children: [
        {
            children: ['playerId', 'fullName', 'nationality'],
        },
        {
            title: 'Special Ranks',
            children: ['singlesSpecialRank', 'doublesSpecialRank'],
        },
        {
            title: 'Tournaments',
            children: ['singlesTournamentsAllowed', 'singlesTournamentsPlayed', 'doublesTournamentsAllowed', 'doublesTournamentsPlayed'],
        },
        {
            title: 'Grand Slams',
            children: ['singlesGrandSlamsAllowed', 'singlesGrandSlamsPlayed', 'doublesGrandSlamsAllowed', 'doublesGrandSlamsPlayed'],
        },
        {
            title: 'Timings',
            children: ['daysAsFromDb', 'firstTournamentAllowed', 'singlesExpirationDate', 'doublesExpirationDate'],
        },
    ],
};

SpecialRankingOverview.layoutAdd = {
    children: [
        {
            children: ['playerId'],
        },
        {
            title: 'Special Ranks',
            children: ['singlesSpecialRank', 'doublesSpecialRank'],
        },
        {
            title: 'Tournaments',
            children: ['singlesTournamentsAllowed', 'singlesTournamentsPlayed', 'doublesTournamentsAllowed', 'doublesTournamentsPlayed'],
        },
        {
            title: 'Grand Slams',
            children: ['singlesGrandSlamsAllowed', 'singlesGrandSlamsPlayed', 'doublesGrandSlamsAllowed', 'doublesGrandSlamsPlayed'],
        },
        {
            title: 'Timings',
            children: ['daysAsFromDb', 'firstTournamentAllowed', 'singlesExpirationDate', 'doublesExpirationDate'],
        },
    ],
};

SpecialRankingOverview.schema = {
    fields: {
        playerId: { type: 'number', displayName: 'Player Id', readonly: true, sortable: true, required: true },
        firstName: { type: 'string', displayName: 'FirstName', readonly: true },
        lastName: { type: 'string', displayName: 'LastName', readonly: true },
        nationality: { type: 'nationality', displayName: 'Nationality', lookup: Object.keys(icoCountries), readonly: true, sortable: true },
        singlesSpecialRank: { type: 'number', displayName: 'Sing SR', sortable: true },
        doublesSpecialRank: { type: 'number', displayName: 'Dbls SR', sortable: true },
        singlesTournamentsAllowed: { type: 'number', displayName: 'Sing Av', sortable: true },
        singlesTournamentsPlayed: { type: 'number', displayName: 'Sing Played', sortable: true },
        doublesTournamentsAllowed: { type: 'number', displayName: 'Db Av', sortable: true },
        doublesTournamentsPlayed: { type: 'number', displayName: 'Db Played', sortable: true },
        singlesGrandSlamsAllowed: { type: 'number', displayName: 'Sing GS Av', sortable: true },
        singlesGrandSlamsPlayed: { type: 'number', displayName: 'Sing GS Played', sortable: true },
        doublesGrandSlamsAllowed: { type: 'number', displayName: 'Db GS Av', sortable: true },
        doublesGrandSlamsPlayed: { type: 'number', displayName: 'Db GS Played', sortable: true },
        lastTournamentBeforeBreak: { type: 'us-date', displayName: 'Last Trn', readonly: true, sortable: true },
        daysAsFromDb: { type: 'number', displayName: 'Days (DB)', readonly: true, sortable: true },
        daysComputed: { type: 'number', displayName: 'Days (comp)', sortable: true },
        weeksAsFromDb: { type: 'number', displayName: 'Weeks (DB)', readonly: true, sortable: true },
        weeksComputed: { type: 'number', displayName: 'Weeks (comp)', readonly: true, sortable: true },
        firstTournamentAllowed: { type: 'us-date', displayName: '1st Tourn Allowed', sortable: true },
        singlesExpirationDate: { type: 'us-date', displayName: 'Singles Expiration Date', sortable: true },
        doublesExpirationDate: { type: 'us-date', displayName: 'Doubles Expiration Date', sortable: true },
        fullName: { type: 'string', displayName: 'Player', readonly: true, sortable: true },
        expired: { type: 'boolean', displayName: 'Expired', sortable: true },
        doublesExpired: { type: 'boolean', displayName: 'Doubles Expired', sortable: true },
    },
};

SpecialRankingOverview.schemaAdd = {
    fields: {
        playerId: { type: 'number', displayName: 'Player Id', sortable: true, required: true },
        singlesSpecialRank: { type: 'number', displayName: 'Sing SR' },
        doublesSpecialRank: { type: 'number', displayName: 'Dbls SR' },
        singlesTournamentsAllowed: { type: 'number', displayName: 'Sing Av' },
        singlesTournamentsPlayed: { type: 'number', displayName: 'Sing Played' },
        doublesTournamentsAllowed: { type: 'number', displayName: 'Db Av' },
        doublesTournamentsPlayed: { type: 'number', displayName: 'Db Played' },
        singlesGrandSlamsAllowed: { type: 'number', displayName: 'Sing GS Av' },
        singlesGrandSlamsPlayed: { type: 'number', displayName: 'Sing GS Played' },
        doublesGrandSlamsAllowed: { type: 'number', displayName: 'Db GS Av' },
        doublesGrandSlamsPlayed: { type: 'number', displayName: 'Db GS Played' },
        lastTournamentBeforeBreak: { type: 'us-date', displayName: 'Last Trn' },
        daysAsFromDb: { type: 'number', displayName: 'Days (DB)', readonly: true },
        daysComputed: { type: 'number', displayName: 'Days (comp)' },
        weeksAsFromDb: { type: 'number', displayName: 'Weeks (DB)', readonly: true },
        weeksComputed: { type: 'number', displayName: 'Weeks (comp)', readonly: true },
        firstTournamentAllowed: { type: 'us-date', displayName: '1st Tourn Allowed' },
        singlesExpirationDate: { type: 'us-date', displayName: 'Singles Expiration Date' },
        expired: { type: 'boolean', displayName: 'Expired' },
        doublesExpirationDate: { type: 'us-date', displayName: 'Doubles Expiration Date' },
        doublesExpired: { type: 'boolean', displayName: 'Doubles Expired'},
    },
};
