<template>
    <div>
        <ErrorDisplay :requestError="this.requestError" @closed="clearError" />

        <div v-if="playerDevelopmentFound" class="player-support">
            <el-tabs value="development">
                <el-tab-pane label="Player" name="development">
                    <div v-loading="loadingPlayerDevelopment">
                        <div class="flex-row mt-0 mb-0">
                            <h3>Player Development Requirements</h3>
                            <span class="spacer"></span>
                            <el-button icon="el-icon-document" @click="onClickExportPdf" v-loading="loadingPdfPdDetailTracking" class="mr-16"
                                >Export PDF
                            </el-button>
                        </div>

                        <div class="flex-row mt-8 mr-16">
                            <el-switch
                                v-model="playerDevelopment.data.ppStatus"
                                inactive-text="Intro"
                                inactive-value="INTRO"
                                active-text="Pro Path"
                                active-value="PROPATH"
                                @change="playerDevelopment.adaptDisplayNames()"
                            >
                            </el-switch>
                            <span class="spacer"></span>
                            <el-button plain type="primary" @click="onShowCurrent">Show current phase</el-button>
                            <el-button plain type="primary" @click="showCompletedReqs = !showCompletedReqs">Show/ hide completed </el-button>
                            <el-button plain type="primary" @click="showOutstandingReqs = !showOutstandingReqs">Show/ hide outstanding </el-button>
                            <el-button plain type="primary" @click="showOverdueReqs = !showOverdueReqs">Show/ hide overdue</el-button>
                        </div>

                        <div v-show="showCompletedReqs" class="flex-row mt-16 mb-0">
                            <FormGroup :model="playerDevelopment" :schema="schema" :layout="layoutCompletedRequriements" />
                        </div>

                        <div v-show="showOutstandingReqs" class="flex-row mt-0 mb-0">
                            <FormGroup :model="playerDevelopment" :schema="schema" :layout="layoutOutstandingRequriements" />
                        </div>

                        <div v-show="showOverdueReqs" class="flex-row mt-0 mb-0">
                            <FormGroup :model="playerDevelopment" :schema="schema" :layout="layoutOverdueRequriements" />
                        </div>

                        <FormGroup :model="playerDevelopment" :schema="schema" :layout="layoutMain" @change="onChange" class="mt-8" />

                        <div v-show="playerDevelopment.data.ppStatus == 'INTRO'">
                            <el-tabs v-model="activeTab">
                                <el-tab-pane label="Intro" name="1">
                                    <div class="mt-16 tab-pane-main">
                                        <FormGroup :model="playerDevelopment" :schema="schema" :layout="layoutIntro" @change="onChange" />
                                    </div>
                                    <div class="tab-pane-sub">
                                        <FormGroup :model="playerDevelopment" :schema="schema" :layout="layoutIntroSub" @change="onChange" />
                                    </div>
                                </el-tab-pane>
                                <el-tab-pane label="Rookie" name="2">
                                    <div class="mt-16 tab-pane-main">
                                        <FormGroup :model="playerDevelopment" :schema="schema" :layout="layoutRookie" @change="onChange" />
                                    </div>
                                    <div class="tab-pane-sub">
                                        <FormGroup :model="playerDevelopment" :schema="schema" :layout="layoutRookieSub" @change="onChange" />
                                    </div>
                                </el-tab-pane>
                                <el-tab-pane label="Elite" name="3">
                                    <div class="mt-16 tab-pane-main">
                                        <FormGroup :model="playerDevelopment" :schema="schema" :layout="layoutElite" @change="onChange" />
                                    </div>
                                    <div class="tab-pane-sub">
                                        <FormGroup :model="playerDevelopment" :schema="schema" :layout="layoutEliteSub" @change="onChange" />
                                    </div>
                                </el-tab-pane>
                                <el-tab-pane label="Premier" name="4">
                                    <div class="mt-16 tab-pane-main">
                                        <FormGroup :model="playerDevelopment" :schema="schema" :layout="layoutPremier" @change="onChange" />
                                    </div>
                                    <div class="tab-pane-sub">
                                        <FormGroup :model="playerDevelopment" :schema="schema" :layout="layoutPremierSub" @change="onChange" />
                                    </div>
                                </el-tab-pane>
                                <el-tab-pane label="Archived Rookie Hours" name="5">
                                    <div class="mt-16 tab-pane-main">
                                        <FormGroup :model="playerDevelopment" :schema="schema" :layout="layoutArchivedRookieHours" @change="onChange" />
                                    </div>
                                    <div class="tab-pane-sub"></div>
                                </el-tab-pane>
                            </el-tabs>
                        </div>
                        <div v-show="playerDevelopment.data.ppStatus == 'PROPATH'">
                            <el-tabs v-model="activeTab">
                                <el-tab-pane label="Pro Path" name="1">
                                    <div class="mt-16 tab-pane-main">
                                        <FormGroup :model="playerDevelopment" :schema="schema" :layout="layoutProPath" @change="onChange" />
                                    </div>
                                    <div class="tab-pane-sub">
                                        <FormGroup :model="playerDevelopment" :schema="schema" :layout="layoutProPathSub" @change="onChange" />
                                    </div>
                                </el-tab-pane>
                                <el-tab-pane label="Rookie" name="2">
                                    <div class="mt-16 tab-pane-main">
                                        <FormGroup :model="playerDevelopment" :schema="schema" :layout="layoutRookie" @change="onChange" />
                                    </div>
                                    <div class="tab-pane-sub">
                                        <FormGroup :model="playerDevelopment" :schema="schema" :layout="layoutRookieSub" @change="onChange" />
                                    </div>
                                </el-tab-pane>
                                <el-tab-pane label="Elite" name="3">
                                    <div class="mt-16 tab-pane-main">
                                        <FormGroup :model="playerDevelopment" :schema="schema" :layout="layoutElite" @change="onChange" />
                                    </div>
                                    <div class="tab-pane-sub">
                                        <FormGroup :model="playerDevelopment" :schema="schema" :layout="layoutEliteSub" @change="onChange" />
                                    </div>
                                </el-tab-pane>
                                <el-tab-pane label="Premier" name="4">
                                    <div class="mt-16 tab-pane-main">
                                        <FormGroup :model="playerDevelopment" :schema="schema" :layout="layoutPremier" @change="onChange" />
                                    </div>
                                    <div class="tab-pane-sub">
                                        <FormGroup :model="playerDevelopment" :schema="schema" :layout="layoutPremierSub" @change="onChange" />
                                    </div>
                                </el-tab-pane>
                                <el-tab-pane label="Archived Rookie Hours" name="5">
                                    <div class="mt-16 tab-pane-main">
                                        <FormGroup :model="playerDevelopment" :schema="schema" :layout="layoutArchivedRookieHours" @change="onChange" />
                                    </div>
                                    <div class="tab-pane-sub"></div>
                                </el-tab-pane>
                            </el-tabs>
                        </div>
                        <el-divider></el-divider>
                        <FormGroup :model="playerDevelopment" :schema="schema" :layout="layoutAdditional" @change="onChange" class="mt-16" />
                    </div>

                    <div v-if="!isBaselineReader" class="dialog-footer">
                        <el-button
                            type="primary"
                            @click="onSave"
                            :disabled="!playerDevelopmentFound || !playerDevelopment.isValidForEdit() || !playerDevelopment.hasChanges()"
                            >Save changes
                        </el-button>
                        <el-button
                            type="primary"
                            @click="onDiscard"
                            :disabled="!playerDevelopmentFound || !playerDevelopment.isValidForEdit() || !playerDevelopment.hasChanges()"
                            >Discard changes
                        </el-button>
                        <span class="spacer"></span>
                        <el-button plain type="danger" v-if="!isBaselineReader" @click="onDelete">Delete Player Development</el-button>
                    </div>
                </el-tab-pane>

                <el-tab-pane label="Coach" name="coach">
                    <PlayerSupportCoach
                        :contactId="playerDevelopment.data.coachId"
                        @new-coach="onNewCoach"
                        @coach-deleted="onCoachDelete"
                        @coach-updated="refreshTeam()"
                        ref="coach"
                        v-loading="loadingPlayerSupportCoach"
                    />
                </el-tab-pane>

                <el-tab-pane label="Parent" name="parent">
                    <PlayerSupportParent
                        :contactId="playerDevelopment.data.parentId"
                        @new-parent="onNewParent"
                        @parent-deleted="onParentDelete"
                        @parent-updated="refreshTeam()"
                        ref="parent"
                        v-loading="loadingPlayerSupportParent"
                    />
                </el-tab-pane>

                <el-tab-pane label="Agent" name="agent">
                    <PlayerSupportAgent
                        :contactId="playerDevelopment.data.agentId"
                        @new-agent="onNewAgent"
                        @agent-deleted="onAgentDelete"
                        @agent-updated="refreshTeam()"
                        ref="agent"
                        v-loading="loadingPlayerSupportAgent"
                    />
                </el-tab-pane>
            </el-tabs>
        </div>

        <div v-else>
            <div class="dialog-footer" v-loading="loadingPlayerDevelopment">
                <span class="spacer"></span>
                <el-button plain type="primary" v-if="!isBaselineReader" @click="onAdd">Add Player Development</el-button>
            </div>

            <div class="flex-row mt-8 mr-16">
              <el-switch
                  v-model="playerDevelopment.data.ppStatus"
                  inactive-text="Intro"
                  inactive-value="INTRO"
                  active-text="Pro Path"
                  active-value="PROPATH"
                  @change="playerDevelopment.adaptDisplayNames()"
              >
              </el-switch>
              <span class="spacer"></span>
              <el-button plain type="primary" @click="onShowCurrent">Show current phase</el-button>
              <el-button plain type="primary" @click="showCompletedReqs = !showCompletedReqs">Show/ hide completed
              </el-button>
              <el-button plain type="primary" @click="showOutstandingReqs = !showOutstandingReqs">Show/ hide
                outstanding
              </el-button>
              <el-button plain type="primary" @click="showOverdueReqs = !showOverdueReqs">Show/ hide overdue</el-button>
            </div>

            <div v-show="showCompletedReqs" class="flex-row mt-16 mb-0">
              <FormGroup :model="playerDevelopment" :allReadonly="isBaselineReader" :schema="schema" :layout="layoutCompletedRequriements"/>
            </div>

            <div v-show="showOutstandingReqs" class="flex-row mt-0 mb-0">
              <FormGroup :model="playerDevelopment" :allReadonly="isBaselineReader" :schema="schema" :layout="layoutOutstandingRequriements"/>
            </div>

            <div v-show="showOverdueReqs" class="flex-row mt-0 mb-0">
              <FormGroup :model="playerDevelopment" :allReadonly="isBaselineReader" :schema="schema" :layout="layoutOverdueRequriements"/>
            </div>

            <FormGroup :model="playerDevelopment" :allReadonly="isBaselineReader" :schema="schema" :layout="layoutMain" @change="onChange"
                       class="mt-8"/>

            <div v-show="playerDevelopment.data.ppStatus == 'INTRO'">
              <el-tabs v-model="activeTab">
                <el-tab-pane label="Intro" name="1">
                  <div class="mt-16 tab-pane-main">
                    <FormGroup :model="playerDevelopment" :allReadonly="isBaselineReader" :schema="schema" :layout="layoutIntro" @change="onChange"/>
                  </div>
                  <div class="tab-pane-sub">
                    <FormGroup :model="playerDevelopment" :allReadonly="isBaselineReader" :schema="schema" :layout="layoutIntroSub" @change="onChange"/>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="Rookie" name="2">
                  <div class="mt-16 tab-pane-main">
                    <FormGroup :model="playerDevelopment" :allReadonly="isBaselineReader" :schema="schema" :layout="layoutRookie" @change="onChange"/>
                  </div>
                  <div class="tab-pane-sub">
                    <FormGroup :model="playerDevelopment" :allReadonly="isBaselineReader" :schema="schema" :layout="layoutRookieSub"
                               @change="onChange"/>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="Elite" name="3">
                  <div class="mt-16 tab-pane-main">
                    <FormGroup :model="playerDevelopment" :allReadonly="isBaselineReader" :schema="schema" :layout="layoutElite" @change="onChange"/>
                  </div>
                  <div class="tab-pane-sub">
                    <FormGroup :model="playerDevelopment" :allReadonly="isBaselineReader" :schema="schema" :layout="layoutEliteSub" @change="onChange"/>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="Premier" name="4">
                  <div class="mt-16 tab-pane-main">
                    <FormGroup :model="playerDevelopment" :allReadonly="isBaselineReader" :schema="schema" :layout="layoutPremier" @change="onChange"/>
                  </div>
                  <div class="tab-pane-sub">
                    <FormGroup :model="playerDevelopment" :allReadonly="isBaselineReader" :schema="schema" :layout="layoutPremierSub"
                               @change="onChange"/>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="Archived Rookie Hours" name="5">
                  <div class="mt-16 tab-pane-main">
                    <FormGroup :model="playerDevelopment" :allReadonly="isBaselineReader" :schema="schema" :layout="layoutArchivedRookieHours"
                               @change="onChange"/>
                  </div>
                  <div class="tab-pane-sub"></div>
                </el-tab-pane>
              </el-tabs>
            </div>
            <div v-show="playerDevelopment.data.ppStatus == 'PROPATH'">
              <el-tabs v-model="activeTab">
                <el-tab-pane label="Pro Path" name="1">
                  <div class="mt-16 tab-pane-main">
                    <FormGroup :model="playerDevelopment" :allReadonly="isBaselineReader" :schema="schema" :layout="layoutProPath" @change="onChange"/>
                  </div>
                  <div class="tab-pane-sub">
                    <FormGroup :model="playerDevelopment" :allReadonly="isBaselineReader" :schema="schema" :layout="layoutProPathSub"
                               @change="onChange"/>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="Rookie" name="2">
                  <div class="mt-16 tab-pane-main">
                    <FormGroup :model="playerDevelopment" :allReadonly="isBaselineReader" :schema="schema" :layout="layoutRookie" @change="onChange"/>
                  </div>
                  <div class="tab-pane-sub">
                    <FormGroup :model="playerDevelopment" :allReadonly="isBaselineReader" :schema="schema" :layout="layoutRookieSub"
                               @change="onChange"/>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="Elite" name="3">
                  <div class="mt-16 tab-pane-main">
                    <FormGroup :model="playerDevelopment" :allReadonly="isBaselineReader" :schema="schema" :layout="layoutElite" @change="onChange"/>
                  </div>
                  <div class="tab-pane-sub">
                    <FormGroup :model="playerDevelopment" :allReadonly="isBaselineReader" :schema="schema" :layout="layoutEliteSub" @change="onChange"/>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="Premier" name="4">
                  <div class="mt-16 tab-pane-main">
                    <FormGroup :model="playerDevelopment" :allReadonly="isBaselineReader" :schema="schema" :layout="layoutPremier" @change="onChange"/>
                  </div>
                  <div class="tab-pane-sub">
                    <FormGroup :model="playerDevelopment" :allReadonly="isBaselineReader" :schema="schema" :layout="layoutPremierSub"
                               @change="onChange"/>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="Archived Rookie Hours" name="5">
                  <div class="mt-16 tab-pane-main">
                    <FormGroup :model="playerDevelopment" :allReadonly="isBaselineReader" :schema="schema" :layout="layoutArchivedRookieHours"
                               @change="onChange"/>
                  </div>
                  <div class="tab-pane-sub"></div>
                </el-tab-pane>
              </el-tabs>
            </div>
            <el-divider></el-divider>
            <FormGroup :model="playerDevelopment" :allReadonly="isBaselineReader" :schema="schema" :layout="layoutAdditional" @change="onChange"
                       class="mt-16"/>
          </div>

        <div v-if="isBaselineReader" class="dialog-footer">
            <el-button
                type="primary"
                @click="onSave"
                :disabled="!playerDevelopmentFound || !playerDevelopment.isValidForEdit() || !playerDevelopment.hasChanges()"
            >Save changes
            </el-button>
            <el-button
                type="primary"
                @click="onDiscard"
                :disabled="!playerDevelopmentFound || !playerDevelopment.isValidForEdit() || !playerDevelopment.hasChanges()"
            >Discard changes
            </el-button>
            <span class="spacer"></span>
            <el-button plain type="danger" v-if="!isBaselineReader" @click="onDelete">Delete Player Development</el-button>
        </div>

        <el-tab-pane label="Coach" name="coach">
          <PlayerSupportCoach
              :contactId="playerDevelopment.data.coachId"
              @new-coach="onNewCoach"
              @coach-deleted="onCoachDelete"
              @coach-updated="refreshTeam()"
              ref="coach"
              v-loading="loadingPlayerSupportCoach"
          />
        </el-tab-pane>

        <el-tab-pane label="Parent" name="parent">
          <PlayerSupportParent
              :contactId="playerDevelopment.data.parentId"
              @new-parent="onNewParent"
              @parent-deleted="onParentDelete"
              @parent-updated="refreshTeam()"
              ref="parent"
              v-loading="loadingPlayerSupportParent"
          />
        </el-tab-pane>

        <el-tab-pane label="Agent" name="agent">
          <PlayerSupportAgent
              :contactId="playerDevelopment.data.agentId"
              @new-agent="onNewAgent"
              @agent-deleted="onAgentDelete"
              @agent-updated="refreshTeam()"
              ref="agent"
              v-loading="loadingPlayerSupportAgent"
          />
        </el-tab-pane> 
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { PlayerDevelopment } from '../../models/player-development';
import { deleteConfirmDto, createConfirmDto, updateConfirmDto } from '../../utils/crud-confirm';
import { isBaselineReader } from '../../services/authGroups';
import _ from 'lodash';
import moment from 'moment';

export default {
    props: ['playerId'],
    data() {
        return {
            schema: PlayerDevelopment.schema,
            layoutMain: PlayerDevelopment.layoutMain,
            layoutIntro: PlayerDevelopment.layoutIntro,
            layoutProPath: PlayerDevelopment.layoutProPath,
            layoutRookie: PlayerDevelopment.layoutRookie,
            layoutElite: PlayerDevelopment.layoutElite,
            layoutPremier: PlayerDevelopment.layoutPremier,
            layoutIntroSub: PlayerDevelopment.layoutIntroSub,
            layoutProPathSub: PlayerDevelopment.layoutProPathSub,
            layoutRookieSub: PlayerDevelopment.layoutRookieSub,
            layoutEliteSub: PlayerDevelopment.layoutEliteSub,
            layoutPremierSub: PlayerDevelopment.layoutPremierSub,
            layoutAdditional: PlayerDevelopment.layoutAdditional,
            layoutCompletedRequriements: PlayerDevelopment.layoutCompletedRequriements,
            layoutOutstandingRequriements: PlayerDevelopment.layoutOutstandingRequriements,
            layoutOverdueRequriements: PlayerDevelopment.layoutOverdueRequriements,
            layoutArchivedRookieHours: PlayerDevelopment.layoutArchivedRookieHours,
            requestError: null,
            visibleSync: false,
            activeTab: '1',
            showCompletedReqs: false,
            showOutstandingReqs: false,
            showOverdueReqs: false,
            isBaselineReader: isBaselineReader(),
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        ...mapGetters('playerDevelopments', [
            'playerDevelopment',
            'loadingPlayerDevelopment',
            'loadingError',
            'playerDevelopmentFound',
            'loadingPdfPdDetailTracking',
        ]),
        ...mapGetters('playerSupportAgent', ['loadingPlayerSupportAgent']),
        ...mapGetters('playerSupportCoach', ['loadingPlayerSupportCoach']),
        ...mapGetters('playerSupportParent', ['loadingPlayerSupportParent']),
        ...mapGetters('playerDetails', ['player']),
    },
    methods: {
        ...mapActions('playerDevelopments', [
            'findPlayerDevelopment',
            'createPlayerDevelopment',
            'updatePlayerDevelopment',
            'deletePlayerDevelopment',
            'exportPdfPdDetailTracking',
        ]),
        refresh() {
            this.findPlayerDevelopment(this.playerId)
                .then(() => {
                    this.playerDevelopment.adaptDisplayNames();
                    this.onShowCurrent();
                    this.refreshTeam();
                })
                .catch((error) => {
                    if (error.response.data.StatusCode === 404) {
                        return;
                    }
                    this.requestError = error;
                    this.$notify({
                        title: 'Get Player Development Error',
                        message: error.response.data.Message,
                        type: 'error',
                        duration: 5000,
                    });
                });
        },
        onShowCurrent() {
            this.activeTab = this.playerDevelopment.data.currPhase + '';
        },
        refreshTeam() {
            this.$refs.coach.refresh();
            this.$refs.parent.refresh();
            this.$refs.agent.refresh();
        },
        onChange(event) {
            let field = event.field;
            let value = event.value;
            if (field === 'ph1InviteDate') {
                this.playerDevelopment.data.ideadlDate = moment(value).add(30, 'd').utcOffset(0, true).toDate();
            }
            if (field === 'ph2InviteDate') {
                this.playerDevelopment.data.rdeadlDate = moment(value).add(90, 'd').utcOffset(0, true).toDate();
            }
            if (field === 'ph3InviteDate') {
                this.playerDevelopment.data.edeadlDate = moment(value).add(365, 'd').utcOffset(0, true).toDate();
            }
            if (field === 'ph4InviteDate') {
                this.playerDevelopment.data.pdeadlDate = moment(value).add(365, 'd').utcOffset(0, true).toDate();
            }
            if (field === 'currPhase') {
                let currPhase = value + '';
                this.activeNames = [currPhase];
            }
        },
        onNewAgent(agent) {
            this.playerDevelopment.data.agentId = agent.contactId;
            this.updatePlayerDevelopment(this.playerDevelopment).then(() => {
                this.refreshTeam();
            });
        },
        onAgentDelete() {
            this.playerDevelopment.data.agentId = null;
            this.updatePlayerDevelopment(this.playerDevelopment).then(() => {
                this.refreshTeam();
            });
        },
        onNewCoach(coach) {
            this.playerDevelopment.data.coachId = coach.contactId;
            this.updatePlayerDevelopment(this.playerDevelopment).then(() => {
                this.refreshTeam();
            });
        },
        onCoachDelete() {
            this.playerDevelopment.data.coachId = null;
            this.updatePlayerDevelopment(this.playerDevelopment).then(() => {
                this.refreshTeam();
            });
        },
        onNewParent(parent) {
            this.playerDevelopment.data.parentId = parent.contactId;
            this.updatePlayerDevelopment(this.playerDevelopment).then(() => {
                this.refreshTeam();
            });
        },
        onParentDelete() {
            this.playerDevelopment.data.parentId = null;
            this.updatePlayerDevelopment(this.playerDevelopment).then(() => {
                this.refreshTeam();
            });
        },
        onAdd() {
            let params = {
                self: this,
                fCreate: this.createPlayerDevelopment,
                dto: new PlayerDevelopment({ playerNbr: this.playerId }),
                dtoName: 'Player Development',
                callback: this.refresh,
            };
            createConfirmDto(params);
        },
        onSave() {
            let params = {
                self: this,
                fUpdate: this.updatePlayerDevelopment,
                dto: this.playerDevelopment,
                dtoName: 'Player Development',
                callback: this.refresh,
            };
            updateConfirmDto(params);
        },
        onDiscard() {
            this.playerDevelopment.data = _.cloneDeep(this.playerDevelopment.original);
        },
        onDelete() {
            let params = {
                self: this,
                fDelete: this.deletePlayerDevelopment,
                vConfirm: this.playerId,
                dto: this.playerDevelopment,
                dtoName: 'Player Development',
                vConfirmName: 'Player ID',
                callback: this.refresh,
            };
            deleteConfirmDto(params);
        },
        clearError() {
            this.requestError = null;
        },
        crudCallback() {
            this.refreshTeam();
            this.requestError = null;
        },

        onClickExportPdf() {
            this.downloadPdf(this.exportPdfPdDetailTracking, `PD_Detail_Tracking_${this.player.data.lastName}_${this.player.data.firstName}.pdf`);
        },

        downloadPdf(fDownload, filename) {
            this.requestError = null;
            fDownload(this.playerId)
                .then((result) => {
                    const file = new Blob([result], { type: 'application/pdf' });
                    saveAs(file, filename);
                    this.$notify({
                        title: 'PDF Export: PD Detail Tracling download successful',
                        message: filename + ' downloaded',
                        type: 'success',
                        duration: 5000,
                    });
                })
                .catch((error) => {
                    this.requestError = error;
                    this.$notify({
                        title: 'PDF Export: PD Detail Tracling download failed',
                        message: filename + ': failed',
                        type: 'error',
                        duration: 7000,
                    });
                });
        },
    },
};
</script>

<style scoped>
.tab-pane-main {
    min-height: 272px;
}

.tab-pane-sub {
    min-height: 136px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
</style>
