import { generateCodeVerifier, generateCodeChallenge } from './pkceService';

const clientId = process.env.VUE_APP_CLIENT_ID;
const tenantId = process.env.VUE_APP_TENANT_ID;
//const clientSecret = process.env.VUE_APP_CLIENT_SECRET;
const redirectUri = process.env.VUE_APP_AD_CALLBACK_URL;
const microsoftUrl = process.env.VUE_APP_MICROSOFT_LOGIN_HOSTNAME;
const scopes = 'openid profile User.Read';
//const scopeApi = 'api://69c1d6ab-6e00-4360-b5f0-126a9f5df4c1/API.Call';

let tokenRenewalInterval = null;

function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map((c) => {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(''),
    );

    return JSON.parse(jsonPayload);
}

export function isTokenExpired(token) {
    const decoded = parseJwt(token);
    const now = Math.floor(Date.now() / 1000);
    return decoded.exp < now;
}

// Function to redirect user to Azure AD for login
export async function login() {
    localStorage.setItem('authorized', 'true');
    const codeVerifier = generateCodeVerifier();
    const codeChallenge = await generateCodeChallenge(codeVerifier);

    localStorage.setItem('code_verifier', codeVerifier);

    const authorizationUrl = `${microsoftUrl}/${tenantId}/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&response_mode=query&scope=${scopes}&code_challenge=${codeChallenge}&code_challenge_method=S256`;

    window.location.href = authorizationUrl;
}

// Function to exchange authorization code for tokens
export async function getToken(authorizationCode) {
    const tokenEndpoint = `${microsoftUrl}/${tenantId}/oauth2/v2.0/token`;
    const params = new URLSearchParams();
    const codeVerifier = localStorage.getItem('code_verifier');

    params.append('client_id', clientId);
    params.append('scope', scopes);
    params.append('code', authorizationCode);
    params.append('redirect_uri', redirectUri);
    params.append('grant_type', 'authorization_code');
    params.append('code_verifier', codeVerifier);

    const response = await fetch(tokenEndpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: params.toString(),
    });

    const tokenData = await response.json();
    return tokenData;
}

// Function to call Microsoft Graph API
export async function callApi(endpoint, accessToken) {
    const whitelist = ['https://graph.microsoft.com/v1.0/me', 'https://graph.microsoft.com/v1.0/users', 'https://graph.microsoft.com/v1.0/me/memberOf'];

    if (!whitelist.includes(endpoint)) {
        throw new Error('The endpoint is not allowed');
    }

    const response = await fetch(endpoint, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });

    if (response.status === 401) {
        const newAccessToken = await renewToken();
        if (newAccessToken) {
            return callApi(endpoint, newAccessToken); // Retry with new access token
        } else {
            throw new Error('Token renewal failed');
        }
    }

    const data = await response.json();
    return data;
}

// Function to get user's profile from idToken
export async function getProfile() {
    const idToken = localStorage.getItem('idToken');
    if (!idToken) {
        throw new Error('No idToken found in localStorage');
    }
    const profile = parseJwt(idToken);
    return profile;
}

// Function to renew token using refresh token
export async function renewToken() {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
        throw new Error('No refresh token available');
    }

    const tokenEndpoint = `${microsoftUrl}/${tenantId}/oauth2/v2.0/token`;
    const params = new URLSearchParams();

    params.append('client_id', clientId);
    params.append('scope', scopes);
    params.append('refresh_token', refreshToken);
    params.append('redirect_uri', redirectUri);
    params.append('grant_type', 'refresh_token');

    const response = await fetch(tokenEndpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: params.toString(),
    });

    const tokenData = await response.json();

    if (tokenData.access_token) {
        localStorage.setItem('accessToken', tokenData.access_token);
        localStorage.setItem('refreshToken', tokenData.refresh_token);
        localStorage.setItem('idToken', tokenData.id_token);

        return tokenData.access_token;
    } else {
        throw new Error('Failed to renew token');
    }
}

// Function to logout
export function logout() {
    // Clear localStorage
    localStorage.removeItem('accessToken');
    localStorage.removeItem('idToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('code_verifier');
    localStorage.removeItem('authorized');

    const logoutUrl = `${microsoftUrl}/${tenantId}/oauth2/v2.0/logout?post_logout_redirect_uri=${redirectUri}`;
    window.location.href = logoutUrl;
}

// Function to check if the user is already authenticated
export async function checkAuthentication() {
    const urlParams = new URLSearchParams(window.location.search);
    const authorizationCode = urlParams.get('code');
    let accessToken = localStorage.getItem('accessToken');

    if (authorizationCode) {
        // Exchange the authorization code for tokens
        const tokenData = await getToken(authorizationCode);
        localStorage.setItem('authorized', 'true');
        localStorage.setItem('accessToken', tokenData.access_token);
        localStorage.setItem('idToken', tokenData.id_token);
        localStorage.setItem('refreshToken', tokenData.refresh_token);

        // Remove the authorization code from the URL
        window.history.replaceState({}, document.title, '/');
        accessToken = tokenData.access_token;
    } else if (accessToken) {
        // Token already exists, check if it's expired
        if (isTokenExpired(accessToken)) {
            accessToken = await renewToken();
        }
    } else {
        // No token, redirect to login
        login();
    }

    return accessToken;
}

/**
 * Starts automatic token renewal.
 * @param {number} intervalInMinutes - Interval in minutes to renew the token.
 */
export function startTokenRenewal(intervalInMinutes = 60) {
    const intervalInMs = intervalInMinutes * 60 * 1000; // Convert to milliseconds

    if (tokenRenewalInterval) {
        clearInterval(tokenRenewalInterval);
    }

    tokenRenewalInterval = setInterval(async () => {
        try {
            const newAccessToken = await renewToken();
            console.info('Token renewed successfully');
        } catch (error) {
            console.error('Token renewal failed:', error);
            clearInterval(tokenRenewalInterval); // Stop automatic renewal on error
            logout(); // Redirect to login if renewal fails
        }
    }, intervalInMs);

    console.info(`Token renewal scheduled every ${intervalInMinutes} minute(s).`);
}
