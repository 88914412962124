<template>
    <div>
        <el-dialog
            v-if="!isBaselineReader"
            :visible.sync="editDialogVisible"
            :close-on-click-modal="false"
            :title="mode === 'edit' ? 'Edit Withdrawal Reason' : 'Add Withdrawal Reason'"
            width="65%"
        >
            <FormGroup :model="selectedPlayerEntryHistory" :schema="playerEntryHistorySchema" :layout="layout" @change="onChange" />
            <div class="dialog-footer">
                <el-button type="primary" @click="saveChanges" :disabled="!selectedPlayerEntryHistory || !selectedPlayerEntryHistory.isValid() || !selectedPlayerEntryHistory.hasChanges()"
                    >Save changes</el-button
                >
                <el-button @click="editDialogVisible = false">Close</el-button>
                <span class="spacer"></span>               
            </div>
        </el-dialog>

        <div class="flex-row mt-0 mb-32">
            <h2>Withdrawal Reason</h2>
            <span class="spacer"></span>

            <SelectPlayer :value="selectedPlayerFullName" class="mr-8" @palyer-selected="playerSelected" />
            <el-tooltip class="item" effect="dark" content="Select Year" placement="top">
                <el-select v-model="selectedTournamentYear" placeholder="Select Tournament Year">
                  <el-option
                      v-for="year in years"
                      :key="year.id"      
                      :label="year.label"                                     
                      :value="year">
                  </el-option>
                </el-select>
            </el-tooltip>
            <SelectEdition2 :value="selectedTournament" class="mr-8" @tournament-selected="tournamentSelected" />

            <el-button icon="el-icon-search" @click="search"></el-button>

            <el-button v-loading="getDownloadingPdf" class="ml-8" plain size="mini" @click="exportPdf">Export PDF</el-button>
        </div>

        <ErrorDisplay :requestError="this.requestError" @closed="clearError" />

        <DetailedInformation :informationArray="this.informationArray()" screenName="Withdrawal Reason" />

        <div v-loading="getLoadingPlayerEntryHistoryData" class="mt-0 mb-32">
            <DataTable
                :fields="[
                    'playerFirstName',
                    'playerLastName',
                    'tournamentName',
                    'tournamentYear',
                    'withDrawalDate',
                    'withDrawalReason'
                ]"
                :items="getPlayerEntryHistoryData.data"
                :schema="playerEntryHistorySchema"
                :sortField="options.sortBy"
                :sortDir="options.sortDir"
                :autoSort="false"
                @header-clicked="setSort"
                @item-clicked="itemClicked"
            />

            <Paginator
                v-if="getPlayerEntryHistoryData.data"
                :data="getPlayerEntryHistoryData"
                :elementsPerPage="options.pageSize"
                @prev-page="prevPage"
                @next-page="nextPage"
                @set-page-size="setPageSize"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { PlayerEntryHistory } from '../models/player-entry-history';
import { retrieveDtos } from '../utils/crud-confirm';
import { isBaselineReader } from '../services/authGroups';

export default {
    components: {
    },
    data() {
        return {
            playerEntryHistorySchema: PlayerEntryHistory.schema,
            layout: PlayerEntryHistory.layout,
            selectedPlayerFullName: null,
            selectedTournament: null,
            requestError: null,
            season: null,
            tournamentId: null,
            playerId: null,
            options: {
                queryString: null,
                sortBy: '',
                sortDir: 'desc',
                page: 0,
                pageSize: 20,
                isWithdrawal: true
            },
            selectedTournamentYear: {id: 1, value: ''+new Date().getFullYear()+'', label: ''+new Date().getFullYear()+''},
            years: this.generateYears(new Date().getFullYear()+1,2005),
            isBaselineReader: isBaselineReader(),
            editDialogVisible: false,
            selectedPlayerEntryHistory: null,
        };
    },   
    computed: {
        ...mapGetters('playerEntryHistory', ['getLoadingPlayerEntryHistoryData', 'getLoadingError', 'getPlayerEntryHistoryData', 'getDownloadingPdf']),
    },
    methods: {
        ...mapActions('playerEntryHistory', ['findPlayerEntryHistory', 'getWithdrawalReasonPdf', 'saveWithdrawalReason']),     
        itemClicked(item) {
            debugger;
            this.selectedPlayerEntryHistory = new PlayerEntryHistory(item);
            this.mode = 'edit';
            this.editDialogVisible = true;
        },
        saveChanges() {
            debugger;
            this.saveWithdrawalReason(this.selectedPlayerEntryHistory)
                .then(() => {
                    this.search();
                    this.requestError = null;
                    this.editDialogVisible = false;
                })
                .catch((error) => {
                    this.requestError = error;
                });
        },
        playerSelected(player) {
            this.playerId = player.id;
            this.selectedPlayerFullName = player.fullName;
        },
        tournamentSelected(tournament) {
            this.selectedTournament = tournament.name;
            //this.season = tournament.season;
            this.tournamentId = tournament.tournamentId;
        },
        generateYears(start, end) {            
            let idcount=0    
            const years = [];             
                for (let year = start; year >= end; year--) {
                    idcount++
                    years.push({ id: idcount, value: ''+year+'', label: ''+year+'' });
                }                
            return years;
        },
        clearError() {
            this.requestError = null;
        },
        setPageSize(size) {
            this.options.pageSize = size;
            this.search();
        },
        prevPage() {
            this.options.page--;
            this.search();
        },
        nextPage() {
            this.options.page++;
            this.search();
        },
        setSort(event) {
            this.options.sortBy = event.property;
            this.options.sortDir = event.order;
            this.search();
        },
        informationArray() {
            return [
                'This screen is used to display Withdrawal reasons for players.',
                'Withdrawal reasons are fetched from table tourn_entry.',
                'Season and tournament are fetched from table tourn_master',
                'Searching is possible by player id <b>or</b> last name <b>or</b> first name. Combined search is not possible.',
                'PDF Export of table is possible by clicking button.',
            ];
        },
        exportPdf() {
            let isPlayerSelected = true;
            let isTournamentSelected = true;
            if (!this.selectedPlayerFullName){
                isPlayerSelected = false;
                this.playerId=0;
            }
            if (!this.selectedTournament){
                isTournamentSelected = false;
                this.season=0;
                this.tournamentId=0;
            }
            if(!isPlayerSelected && !isTournamentSelected){
                this.requestError = {
                    message: 'Choose a player and a tournament to export pdf',
                };
                return;
            }
            if (!this.selectedTournamentYear || this.selectedTournamentYear.id == 0 ) {
                this.requestError = {
                    message: 'Choose a tournament year with player or a tournament to export pdf',
                };
                return;
            }                    
            let request = {
                season: this.selectedTournamentYear.value,
                playerId: this.playerId,
                tournamentId: this.tournamentId
            };
            this.downloadWithdrawalPdf(this.getWithdrawalReasonPdf, 'Withdrawal_Reason.pdf', request);
        },
        downloadWithdrawalPdf(fDownload, filename, request) {
            this.requestError = null;
            fDownload(request)
                    .then((result) => {
                        const file = new Blob([result], { type: 'application/pdf' });
                        saveAs(file, filename);
                        this.$notify({
                            title: 'PDF download successful',
                            message: filename + ' downloaded',
                            type: 'success',
                            duration: 5000,
                        });
                    })
                    .catch((error) => {
                        this.requestError = error;
                        this.$notify({
                            title: 'PDF download failed',
                            message: filename + ': ',
                            type: 'error',
                            duration: 7000,
                        });
                    });
        },
        search() {
            let isPlayerSelected = true;
            let isTournamentSelected = true;
            if (!this.selectedPlayerFullName){
                isPlayerSelected = false;
                this.playerId=0;
            }
            if (!this.selectedTournament){
                isTournamentSelected = false;
                this.season=0;
                this.tournamentId=0;
            }
            if(!isPlayerSelected && !isTournamentSelected){
                this.requestError = {
                    message: 'Choose a player or a tournament',
                };
                return;
            }            
            if (!this.selectedTournamentYear || this.selectedTournamentYear.id == 0 ) {
                this.requestError = {
                    message: 'Choose a tournament year with player or a tournament',
                };
                return;
            }           
            const body = {
                options: this.options,
                season: this.selectedTournamentYear.value,
                playerId: this.playerId,
                tournamentId: this.tournamentId
            };
            let params = {
                self: this,
                fRetrieve: this.findPlayerEntryHistory,
                options: body,
                dtosName: 'Withdrawal Reason',
            };
            retrieveDtos(params);
        }
    },
};
</script>

<style scoped>
.input-search {
    max-width: 256px;
}

.year-select {
    max-width: 96px;
}
</style>
